.searchWrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  width: 75%;

  @media (max-width: 820px) {
    width: 90%;
    top: 15%;

    .mb-ipad {
      margin-bottom: 1rem;
    }
  }

  .searchButton {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-top: 15px;
  }

  .sticky-col {
    position: sticky;
    left: 0;
    background-color: white; /* Set background color as needed */
    z-index: 1; /* Ensure the sticky column is above other columns */
    /* Specify a width for the sticky column */
    /* For example, if you want it to be 100px wide: */
    width: 100px;
  }
}


.stickyColmn {
  position: sticky;
  left: 0;
  background-color: white; /* Set background color as needed */
  z-index: 1; /* Ensure the sticky column is above other columns */
  /* Specify a width for the sticky column */
  /* For example, if you want it to be 100px wide: */
  width: 100px;
}